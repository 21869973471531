
.contenedor-servicios{
    background-size: cover;
    background-repeat: repeat;
}

.title-servicios{
    font-family: 'Roboto', sans-serif;
    color: #304269;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 5px;
}

.line{
    background-color: #407bff;
    width: 10%;
    height: 5px;
    border: none;
}

.paragraph-servicios{
    color: #808080;
    font-size: 1.2em;
}

.Card{
    display: flex;
    flex-direction: row;
    margin-top: 2.8em;
    border: 3px solid #407bff;
    border-radius: 25px;
    height: 55vh;
    overflow: hidden;
}

.img-servicio{
    background-size: cover;
    background-position: center;
}


.title-card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 1em;
}

.icon-card{
    font-size: 2em;
    color: #304269;

}

.label-card{
    font-size: 1em;
    font-weight: bold;
    color: #304269;
}

.label-card-desc{
    font-size: .8em;
    text-align: justify;
    font-weight: bold;
}

.service-container{
    display: flex;
    flex-direction: column;

    justify-content: center;
    padding: 5px;
}

.anclabtn{
    width: 100%;
    text-align: center;
}

.btn-service{
    height: 40px;
    width: 35%;
    align-self: center;
    text-decoration: none !important;
    border: 1px solid #43a047;
    cursor: pointer;
    border-radius: 5px;
}

.list-services::before {
    content: "•"; 
    color: blue;
    display: inline-block; 
    width: 1em;
    margin-left: 0em;
    font-size: 1.5em;
}

.list-services-especial::before {
    content: "•"; 
    color: blue;
    display: inline-block; 
    width: 1em;
    margin-left: 0em;
    font-size: 1.5em;
}

.carousel .carousel-item {
    width:280px !important;
}

.mobile-image{
    width: 100%;
    border-radius: 15px;
    margin-top: 10px;
}

@media screen and (max-width:600px){
    .line{
        width: 20%;
    }
    .title-servicios{
        font-size: 1.5em;
    }

    .label-card{
        font-size: 1.1em;
        margin:0;
    }

    .label-card-desc{
        font-size: 1em;
    }

    .Card{
       height: 65vh;

       display: flex;
       flex-direction: column;
    }

    .Card-textLong{
        height: 70vh;
    }

    .list-services-especial, .list-services {
        font-size: small;
        margin-left: 0em;
    }

    .list-services{
        margin-left: 0em !important;
    }

    .img-servicio{
        height: auto !important;
    }
    .btn-service{
        width: 40%;
        margin-top: 10px;
    }
}

@media screen and (min-width:601px) and (max-width:860px){
    .contenedor-servicios{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .carousel .carousel-item {
        width:200px !important;
    }
    .Card-textLong{
        height: 55vh;
    }
}