
.contenedor-login{
    height: 80vh;
}

.contenedor-form{
    display: flex;
    align-items: center;
}
.formulario{
    border: 1px solid blue;
    padding: 2em;
    border-radius: 25px;
}

.title-login{
    margin-bottom: 1em;
}

.btn-login-contenedor{
    display: flex;
    justify-content: center;
    margin-top: 1em;
}