@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body{
    font-family: 'Montserrat', sans-serif;
}

.h-100{
    height: 100% !important;
}

.mt-0{
    margin-top: 0 !important;
}

.m-0{
    margin: 0 !important;
}

.mb-0{
    margin-bottom: 0 !important;
}

.p-0{
    padding: 0 !important;
}

.hide{
    display: none;
}