.contact-contenedor{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.contacto{
    padding: 1em 0;
    border: 2px solid#407bff;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 25px;
}

.contact-paragraph{
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
    color:#808080
}

.btn-contact{
    width: 35%;
    height:45px;
}

@media screen and (max-width:600px){
    .contacto{
        padding: .5em 0;
        margin-top: .5em;
        margin-bottom: .8em;
    }
    .btn-contact{
        margin:0;
        width: 40%;
    }
}

@media screen and (min-width:601px) and (max-width:860px){
    .contacto{
        padding: .5em 0;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .btn-contact{
        margin:0;
        width: 25%;
        height: 40px;
    }
}

