
.clientes-contenedor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.img-cliente{
    width: 20%;
}