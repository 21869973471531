
.contenedor-facturas{
    padding: 1em 2em;
}

.price_total{
    font-size: 1.3em;
}
.formulario-facturas{
    position: relative;
}
.fab-submit-btn{
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 2em;
}

.fab-goBack{
    position: absolute;
    margin: 1em
}