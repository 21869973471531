
.contenedor-panel{
    height: 50vh;
}

.btn-panel-contenedor{
    margin: 2em 0;
}

.opciones-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}