@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);

.navbar{
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
}

.logo{
    padding: 0 1em !important;
}

.img-logo{
    width: 20%;
    margin-left: 1em;
}

.links-container{
    padding: 0 2em;
}

.contact{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #304269 !important;
    font-size: 1.2em;
}

.link{
    font-family: 'Roboto', sans-serif;
    color: #91BED4;
    font-size: 1.2em;
    font-weight: bold;
}

.navbar-icon{
    color: black;
}

@media only screen and (min-width: 601px){
    nav{
        height: 80px !important;
    }
}

@media only screen and (max-width: 601px){
    .brand-logo{
        text-align: center;
        margin-left: 0;
    }

    .img-logo{
       width: 50%;
       /* margin-left: 0em; */
    }


}

@media screen and (min-width:601px) and (max-width:860px){
    .img-logo-container{
        display: flex;
        justify-content: center;
    }
    .img-logo{
        width: 40%;
        /* margin-left: 0em; */
    }
}

.background{
    height: 80vh;
    width: 100%;
    /* background-size: cover; */
    /* background-position: center; */
    background-repeat: repeat; 
}

.contenedor-descripcion{
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.title-descripcion{
    color: #304269;
    margin-bottom: 0;
    font-weight: bold;
}

.paragraph-title{
    color: #91BED4;
    font-weight: bold;

}

.paragraph-descripcion{
    font-family: 'Open Sans', sans-serif;
    color: #808080;
    margin-top: 20px;
}

.btn-cotizar{
    font-family: 'Roboto', sans-serif;
    color: #FFF;
    background-color: #F26101;
    width: 45%;
    border-radius: 5px;
    height: 60px;
    font-size: 1.2em;
    text-transform: none;
    font-weight: bold;
    margin-top: 20px;
}

.contenedor-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/*Modal Contacto*/

.modal-contacto{
    border-radius: 15px;
    /* width: 45%; */
    padding: .5em;
}

.title-modal{
    margin-bottom: 0;
}

.line-contacto{
    height: 1px;
    background-color:#448aff;
    width: 40%;
    margin: 0 auto;
}

.form-contacto{
    padding: 0 1em;
    margin-bottom: 0;
}

@media screen and (max-width:600px){
    .background{
        height: 70vh;
    }
    .title-descripcion{
        font-size: 1.6em;
        margin-left: 0;
    }
    .paragraph-title{
        font-size: 1.6em;
    }
    .paragraph-descripcion{
        font-size: .9em;
        font-weight: bold;
        text-align: left;
    }

    .btn-cotizar{
        font-size: 1em;
        width: 60%;
        height: 50px;
    }

    .modal-contacto{
        padding: 0;
        padding-bottom: 1em !important;
    }
    .form-contacto{
        padding: 0;
    }

    .btn-submit-contact{
        margin-right: 1em !important;
    }
}

@media screen and (min-width:601px) and (max-width:860px){
    .background{
        height: 70vh;
    }

    .paragraph-descripcion{
        font-size: 1.2em;
        font-weight: bold;

    }

    .btn-cotizar{
        font-size: 1.1em;
        width: 30%;
        height: 50px;
    }
}

@media screen and (min-width:861px) and (max-width:1200px){
    .contenedor-900{
        width: 90% !important;
    }
}



.contenedor-servicios{
    background-size: cover;
    background-repeat: repeat;
}

.title-servicios{
    font-family: 'Roboto', sans-serif;
    color: #304269;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 5px;
}

.line{
    background-color: #407bff;
    width: 10%;
    height: 5px;
    border: none;
}

.paragraph-servicios{
    color: #808080;
    font-size: 1.2em;
}

.Card{
    display: flex;
    flex-direction: row;
    margin-top: 2.8em;
    border: 3px solid #407bff;
    border-radius: 25px;
    height: 55vh;
    overflow: hidden;
}

.img-servicio{
    background-size: cover;
    background-position: center;
}


.title-card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 1em;
}

.icon-card{
    font-size: 2em;
    color: #304269;

}

.label-card{
    font-size: 1em;
    font-weight: bold;
    color: #304269;
}

.label-card-desc{
    font-size: .8em;
    text-align: justify;
    font-weight: bold;
}

.service-container{
    display: flex;
    flex-direction: column;

    justify-content: center;
    padding: 5px;
}

.anclabtn{
    width: 100%;
    text-align: center;
}

.btn-service{
    height: 40px;
    width: 35%;
    align-self: center;
    text-decoration: none !important;
    border: 1px solid #43a047;
    cursor: pointer;
    border-radius: 5px;
}

.list-services::before {
    content: "•"; 
    color: blue;
    display: inline-block; 
    width: 1em;
    margin-left: 0em;
    font-size: 1.5em;
}

.list-services-especial::before {
    content: "•"; 
    color: blue;
    display: inline-block; 
    width: 1em;
    margin-left: 0em;
    font-size: 1.5em;
}

.carousel .carousel-item {
    width:280px !important;
}

.mobile-image{
    width: 100%;
    border-radius: 15px;
    margin-top: 10px;
}

@media screen and (max-width:600px){
    .line{
        width: 20%;
    }
    .title-servicios{
        font-size: 1.5em;
    }

    .label-card{
        font-size: 1.1em;
        margin:0;
    }

    .label-card-desc{
        font-size: 1em;
    }

    .Card{
       height: 65vh;

       display: flex;
       flex-direction: column;
    }

    .Card-textLong{
        height: 70vh;
    }

    .list-services-especial, .list-services {
        font-size: small;
        margin-left: 0em;
    }

    .list-services{
        margin-left: 0em !important;
    }

    .img-servicio{
        height: auto !important;
    }
    .btn-service{
        width: 40%;
        margin-top: 10px;
    }
}

@media screen and (min-width:601px) and (max-width:860px){
    .contenedor-servicios{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .carousel .carousel-item {
        width:200px !important;
    }
    .Card-textLong{
        height: 55vh;
    }
}

.clientes-contenedor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.img-cliente{
    width: 20%;
}
.contact-contenedor{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.contacto{
    padding: 1em 0;
    border: 2px solid#407bff;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 25px;
}

.contact-paragraph{
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
    color:#808080
}

.btn-contact{
    width: 35%;
    height:45px;
}

@media screen and (max-width:600px){
    .contacto{
        padding: .5em 0;
        margin-top: .5em;
        margin-bottom: .8em;
    }
    .btn-contact{
        margin:0;
        width: 40%;
    }
}

@media screen and (min-width:601px) and (max-width:860px){
    .contacto{
        padding: .5em 0;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .btn-contact{
        margin:0;
        width: 25%;
        height: 40px;
    }
}


.contenedor-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3.5em;
}

.copyrigth{
    color: #808080;
    font-weight: bold;
}

.redes-sociales{
    display: flex;
    align-items: center;
}

.icon-footer{
    color: #F26101;
    font-size: 1.8em;
    margin:15px
}

@media screen and (max-width:600px){
    .contenedor-footer{
        padding: 0 1.5em;
    }
    .copyrigth{
        font-size: .8em;
    }
    .icon-footer{
        font-size: 1.5em;
    }
}

.contenedor-login{
    height: 80vh;
}

.contenedor-form{
    display: flex;
    align-items: center;
}
.formulario{
    border: 1px solid blue;
    padding: 2em;
    border-radius: 25px;
}

.title-login{
    margin-bottom: 1em;
}

.btn-login-contenedor{
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.contenedor-panel{
    height: 50vh;
}

.btn-panel-contenedor{
    margin: 2em 0;
}

.opciones-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fab-add-cliente{
    position: absolute;
    /* left: 0; */
    right: 0;
    top: 0;
    margin-right: 2.5em;
}

.card-client{
    margin-top: 2em;
    padding: 1em !important;
    border: 2px solid #2196f3;
    border-radius: 15px;
}

.buttons-client{
    display: flex;
    justify-content: space-around;
}

.card-input{
    margin-top: 2.2em;

}


.contenedor-facturas{
    padding: 1em 2em;
}

.price_total{
    font-size: 1.3em;
}
.formulario-facturas{
    position: relative;
}
.fab-submit-btn{
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 2em;
}

.fab-goBack{
    position: absolute;
    margin: 1em
}

.titles-reportScreen{
    font-size: 1.5em;
}

.form-reportes{
    padding: 0 2em ;
}

.green-text{
    font-weight: bold;
    text-decoration: underline;
}




.loading-contenedor{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.spinner-loading{
    font-size: 4em;
    color: #099268;
}

.label-loading{
    color: #099268;
    font-weight: bold;
}

/*Boton Atras*/

.btn-goBack{
    position: absolute;
    margin: 1em 2em;
}
.contenedor-spinner-data{
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-data{
    font-size: 2.5em;
}

.contenedor-card-informe{
    border: 2px solid #2196f3;
    border-radius: 25px;
    padding: 1em .5em;
    margin-top: 2em;
}


.card-informe{
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-info{
    display: flex;
    align-items: center;
    justify-content: center;
}


.btns-contenedor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.label-elaborado{
    margin-top: .5em;
    margin-bottom: 0;
}

@media screen and (max-width:600px){
    .contenedor-card-informe{
        margin-top: 1em;
    }
    .card-informe{
        flex-direction: column;
    }
    .btns-contenedor{
        margin-top: .5em;
        justify-content: space-evenly;
    }
}



.btn-Back{
    position: absolute;
    margin: 1em 2em;
}

.background-services{
    
    background-size: cover;
}

.service__container{
    padding: 100px 0;

}

.service__image{
    width: 100%;
    border-radius: 15px;
}

.service__label-title{
    font-weight: bold;
    text-decoration: none;
}

.service__label-desc{
    font-weight: bold;
}

.service__label-list{
    font-size: 1.2em;
    text-decoration: none;
}

.service__label-btn{
    padding: 1em;
    display: flex;
    justify-content: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width:600px){
    .background-services{
        background-image: none !important;
    }
    
    .service__container{
        padding: 30px 0;
    }

    .service__label-title{
        font-size: 1.4em;
        /* margin-top: 0; */
    }

    .service__label-desc{
        margin-top: 0;
        font-size: 1em;
    }

    .service__label-list{
        font-size: 1em;
    }

}

@media screen and (min-width:601px) and (max-width:860px){
    .service__container{
        padding: 80px 0;
    }

    .service__label-title{
        margin-top: 0;
        font-size: 1.5em;
    }

    .service__label-desc{
        font-size: 1.2em;
        margin-top: 0;
    }
}
body{
    font-family: 'Montserrat', sans-serif;
}

.h-100{
    height: 100% !important;
}

.mt-0{
    margin-top: 0 !important;
}

.m-0{
    margin: 0 !important;
}

.mb-0{
    margin-bottom: 0 !important;
}

.p-0{
    padding: 0 !important;
}

.hide{
    display: none;
}
