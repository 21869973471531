
.fab-add-cliente{
    position: absolute;
    /* left: 0; */
    right: 0;
    top: 0;
    margin-right: 2.5em;
}

.card-client{
    margin-top: 2em;
    padding: 1em !important;
    border: 2px solid #2196f3;
    border-radius: 15px;
}

.buttons-client{
    display: flex;
    justify-content: space-around;
}

.card-input{
    margin-top: 2.2em;

}
