
.background{
    height: 80vh;
    width: 100%;
    /* background-size: cover; */
    /* background-position: center; */
    background-repeat: repeat; 
}

.contenedor-descripcion{
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.title-descripcion{
    color: #304269;
    margin-bottom: 0;
    font-weight: bold;
}

.paragraph-title{
    color: #91BED4;
    font-weight: bold;

}

.paragraph-descripcion{
    font-family: 'Open Sans', sans-serif;
    color: #808080;
    margin-top: 20px;
}

.btn-cotizar{
    font-family: 'Roboto', sans-serif;
    color: #FFF;
    background-color: #F26101;
    width: 45%;
    border-radius: 5px;
    height: 60px;
    font-size: 1.2em;
    text-transform: none;
    font-weight: bold;
    margin-top: 20px;
}

.contenedor-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/*Modal Contacto*/

.modal-contacto{
    border-radius: 15px;
    /* width: 45%; */
    padding: .5em;
}

.title-modal{
    margin-bottom: 0;
}

.line-contacto{
    height: 1px;
    background-color:#448aff;
    width: 40%;
    margin: 0 auto;
}

.form-contacto{
    padding: 0 1em;
    margin-bottom: 0;
}

@media screen and (max-width:600px){
    .background{
        height: 70vh;
    }
    .title-descripcion{
        font-size: 1.6em;
        margin-left: 0;
    }
    .paragraph-title{
        font-size: 1.6em;
    }
    .paragraph-descripcion{
        font-size: .9em;
        font-weight: bold;
        text-align: left;
    }

    .btn-cotizar{
        font-size: 1em;
        width: 60%;
        height: 50px;
    }

    .modal-contacto{
        padding: 0;
        padding-bottom: 1em !important;
    }
    .form-contacto{
        padding: 0;
    }

    .btn-submit-contact{
        margin-right: 1em !important;
    }
}

@media screen and (min-width:601px) and (max-width:860px){
    .background{
        height: 70vh;
    }

    .paragraph-descripcion{
        font-size: 1.2em;
        font-weight: bold;

    }

    .btn-cotizar{
        font-size: 1.1em;
        width: 30%;
        height: 50px;
    }
}

@media screen and (min-width:861px) and (max-width:1200px){
    .contenedor-900{
        width: 90% !important;
    }
}

