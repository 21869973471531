
.contenedor-card-informe{
    border: 2px solid #2196f3;
    border-radius: 25px;
    padding: 1em .5em;
    margin-top: 2em;
}


.card-informe{
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-info{
    display: flex;
    align-items: center;
    justify-content: center;
}


.btns-contenedor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.label-elaborado{
    margin-top: .5em;
    margin-bottom: 0;
}

@media screen and (max-width:600px){
    .contenedor-card-informe{
        margin-top: 1em;
    }
    .card-informe{
        flex-direction: column;
    }
    .btns-contenedor{
        margin-top: .5em;
        justify-content: space-evenly;
    }
}

