

.loading-contenedor{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.spinner-loading{
    font-size: 4em;
    color: #099268;
}

.label-loading{
    color: #099268;
    font-weight: bold;
}

/*Boton Atras*/

.btn-goBack{
    position: absolute;
    margin: 1em 2em;
}