.contenedor-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3.5em;
}

.copyrigth{
    color: #808080;
    font-weight: bold;
}

.redes-sociales{
    display: flex;
    align-items: center;
}

.icon-footer{
    color: #F26101;
    font-size: 1.8em;
    margin:15px
}

@media screen and (max-width:600px){
    .contenedor-footer{
        padding: 0 1.5em;
    }
    .copyrigth{
        font-size: .8em;
    }
    .icon-footer{
        font-size: 1.5em;
    }
}