
.background-services{
    
    background-size: cover;
}

.service__container{
    padding: 100px 0;

}

.service__image{
    width: 100%;
    border-radius: 15px;
}

.service__label-title{
    font-weight: bold;
    text-decoration: none;
}

.service__label-desc{
    font-weight: bold;
}

.service__label-list{
    font-size: 1.2em;
    text-decoration: none;
}

.service__label-btn{
    padding: 1em;
    display: flex;
    justify-content: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width:600px){
    .background-services{
        background-image: none !important;
    }
    
    .service__container{
        padding: 30px 0;
    }

    .service__label-title{
        font-size: 1.4em;
        /* margin-top: 0; */
    }

    .service__label-desc{
        margin-top: 0;
        font-size: 1em;
    }

    .service__label-list{
        font-size: 1em;
    }

}

@media screen and (min-width:601px) and (max-width:860px){
    .service__container{
        padding: 80px 0;
    }

    .service__label-title{
        margin-top: 0;
        font-size: 1.5em;
    }

    .service__label-desc{
        font-size: 1.2em;
        margin-top: 0;
    }
}