
.titles-reportScreen{
    font-size: 1.5em;
}

.form-reportes{
    padding: 0 2em ;
}

.green-text{
    font-weight: bold;
    text-decoration: underline;
}

