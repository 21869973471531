
.navbar{
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
}

.logo{
    padding: 0 1em !important;
}

.img-logo{
    width: 20%;
    margin-left: 1em;
}

.links-container{
    padding: 0 2em;
}

.contact{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #304269 !important;
    font-size: 1.2em;
}

.link{
    font-family: 'Roboto', sans-serif;
    color: #91BED4;
    font-size: 1.2em;
    font-weight: bold;
}

.navbar-icon{
    color: black;
}

@media only screen and (min-width: 601px){
    nav{
        height: 80px !important;
    }
}

@media only screen and (max-width: 601px){
    .brand-logo{
        text-align: center;
        margin-left: 0;
    }

    .img-logo{
       width: 50%;
       /* margin-left: 0em; */
    }


}

@media screen and (min-width:601px) and (max-width:860px){
    .img-logo-container{
        display: flex;
        justify-content: center;
    }
    .img-logo{
        width: 40%;
        /* margin-left: 0em; */
    }
}